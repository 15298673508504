import { Locale } from "@lib";
import { ReportKind } from "../report-filter-controller";
import { IReport, IReportOptions, IReportResult } from "./report";
import { FilterPair } from "@/utils/filter";
import { DateTimeFormatOptions, Formatter, MoneyFormatOptions } from "@/utils/formatter";
import { currencyHeader } from "@/filters/money";

export class FinancesTableReport implements IReport {
    public async generate(options: IReportOptions): Promise<IReportResult> {
        const dates = options.filter.dates as FilterPair;

        const from = dates[0];
        const to = dates[1];
        const accountId = options.filter.account as string;

        const usecase = options.context.$alt.system.usecase.createReportUseCase();
        const report = await usecase.finances(options.companyId, {
            timezone: new Date().getTimezoneOffset().toString(),
            from,
            to,
            account: accountId,
        });

        const accountName = report.account.info.name;

        const classHeaderName = "va-td text-center text-xl";
        const classHeaderMoney = "va-td va-td-money text-center text-xl";

        const classCellName = "va-td text-nowrap text-xl";
        const classCellMoney = "va-td va-td-money text-center text-xl";
        const classCellMoneyGreen = `${classCellMoney} text-success`;
        const classCellMoneyRed = `${classCellMoney} text-danger`;

        const classFooterName = "va-td text-right text-xl font-bold va-td-noborder";
        const classFooterMoney = "va-td va-td-money text-center text-xl font-bold";

        const header: any[] = [
            {
                cells: [
                    {
                        value: "Дата и время",
                        class: classHeaderMoney,
                    },
                    {
                        value: "Сотрудник",
                        class: classHeaderName,
                    },
                    {
                        value: "Комментарий",
                        class: classHeaderName,
                    },
                    {
                        value: currencyHeader("Сумма", report.account.info.currency),
                        class: classHeaderMoney,
                    },
                    {
                        value: currencyHeader("Остаток", report.account.info.currency),
                        class: classHeaderMoney,
                    },
                ],
            },
        ];

        const body: any[] = [];
        for (const transaction of report.transactions) {
            const amount = transaction.amount;
            const amountStr = Formatter.money(amount);

            body.push({
                cells: [
                    {
                        value: Formatter.datetime(transaction.datetime, { format: "L LT" }),
                        class: classCellMoney,
                    },
                    {
                        value: transaction.employee,
                        class: classCellName,
                    },
                    {
                        value: transaction.description,
                        class: classCellName,
                    },
                    {
                        value: amount > 0 ? `+${amountStr}` : amountStr,
                        class: amount > 0 ? classCellMoneyGreen : amount < 0 ? classCellMoneyRed : classCellMoney,
                    },
                    {
                        value: Formatter.money(transaction.balance),
                        class: classCellMoney,
                    },
                ],
            });
        }

        const footer: any[] = [
            {
                cells: [
                    {
                        value: "На начало периода:",
                        class: classFooterName,
                        colspan: 4,
                    },
                    {
                        value: Formatter.money(report.balanceStart),
                        class: classFooterMoney,
                    },
                ],
            },
            {
                cells: [
                    {
                        value: "Итого приход:",
                        class: classFooterName,
                        colspan: 4,
                    },
                    {
                        value: Formatter.money(report.incomes),
                        class: classFooterMoney,
                    },
                ],
            },
            {
                cells: [
                    {
                        value: "Итого расход:",
                        class: classFooterName,
                        colspan: 4,
                    },
                    {
                        value: Formatter.money(report.expenses),
                        class: classFooterMoney,
                    },
                ],
            },
            {
                cells: [
                    {
                        value: "На конец периода:",
                        class: classFooterName,
                        colspan: 4,
                    },
                    {
                        value: Formatter.money(report.balanceEnd),
                        class: classFooterMoney,
                    },
                ],
            },
            {
                cells: [
                    {
                        value: "Прибыль:",
                        class: classFooterName,
                        colspan: 4,
                    },
                    {
                        value: Formatter.money(report.incomes - report.expenses),
                        class: classFooterMoney,
                    },
                ],
            },
        ];

        const format = "LL";
        const subtitle =
            from === to
                ? Formatter.datetime(from, { format })
                : `${Formatter.datetime(from, { format })} - ${Formatter.datetime(to, { format })}`;

        return {
            title: `Движение средств по счёту "${accountName}"`,
            subtitle: subtitle,
            items: [
                {
                    kind: ReportKind.Table,
                    table: {
                        header,
                        body,
                        footer,
                    },
                },
            ],
        };
    }

    private dtFormat(date: number | string | Date): string {
        return Formatter.datetime(date, { format: "MMM 'YY" });
    }
}
