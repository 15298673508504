import { ICompany, IOffice, IOrder, IOrderType, IProductType, ISale, IShop } from "@lib";
import { OrderMacroReplacer } from "./order.macro-replacer";
import { SaleMacroReplacer } from "./sale.macro-replacer";

export interface IMacroOrderContext {
    company: ICompany;
    offices: IOffice[];
    orderTypes: IOrderType[];
    productTypes: IProductType[];
}

export interface IMacroSaleContext {
    company: ICompany;
    shops: IShop[];
}

export abstract class Macro {
    /** Заменить макросы для заявки. */
    public static replaceForOrder(template: string, order: IOrder, context: IMacroOrderContext): string {
        const office = context.offices.find(o => o.id === order.office);
        const orderType = context.orderTypes.find(t => t.id === order.type);

        if (!office) {
            return template;
        }

        const replacer = new OrderMacroReplacer({
            company: context.company,
            office: office,
            order: order,
            orderType: orderType,
            productTypes: context.productTypes,
            client: order.clientRef,
            manager: order.managerRef,
        });

        return replacer.replace(template);
    }

    /** Заменить макросы для продажи. */
    public static replaceForSale(template: string, sale: ISale, context: IMacroSaleContext): string {
        const shop = context.shops.find(s => s.id === sale.shop);

        if (!shop) {
            return template;
        }

        const replacer = new SaleMacroReplacer({
            company: context.company,
            shop: shop,
            sale: sale,
        });

        return replacer.replace(template);
    }
}
