import { ReportKind } from "../report-filter-controller";
import { IReport, IReportOptions, IReportResult } from "./report";
import { getChartOptions } from "../data";
import { FilterPair } from "@/utils/filter";
import { Formatter } from "@/utils/formatter";

export class ClientSourcesChartReport implements IReport {
    public async generate(options: IReportOptions): Promise<IReportResult> {
        const dates = options.filter.dates as FilterPair;

        const from = dates[0];
        const to = dates[1];

        const usecase = options.context.$alt.system.usecase.createReportUseCase();
        const report = await usecase.clients(options.companyId, {
            timezone: new Date().getTimezoneOffset().toString(),
            from,
            to,
        });

        const categories = report.map(groupedClient => {
            return groupedClient.clientSource?.name ?? "Неизвестно";
        });

        const series = [
            {
                name: "Количество",
                data: report.map(groupedClient => groupedClient.quantity),
            },
        ];

        const format = "LL";
        const subtitle =
            from === to
                ? Formatter.datetime(from, { format })
                : `${Formatter.datetime(from, { format })} - ${Formatter.datetime(to, { format })}`;

        return {
            title: "Отчёт по источникам клиентов",
            subtitle,
            items: [
                {
                    kind: ReportKind.Chart,
                    chart: {
                        type: "histogram",
                        series,
                        options: getChartOptions(categories),
                    },
                },
            ],
        };
    }
}
