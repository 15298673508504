import { SaleMacroList } from "@core/usecases/template/macro-replacers/sale-document.macro-list";
import { IMacro, ISaleMacroOpenContext } from "@core/usecases/template/macro";
import { IMacroReplacer } from "./macro-replacer";

export class SaleMacroReplacer implements IMacroReplacer {
    // %(макрос:аргумент1,аргумент2)
    private readonly regexp = /%\((?<macro>[\p{L}\p{N}.]*)(:?(?<args>[\p{L}\p{N}.,]*)?)\)/giu;
    // \p{L} - юникодные буквы
    // \p{N} - юникодные цифры
    // регулярки: https://developer.mozilla.org/ru/docs/Web/JavaScript/Guide/Regular_Expressions
    // юникодные символы: https://learn.javascript.ru/regexp-unicode
    // проверка регулярок: https://regex101.com/

    private readonly context: ISaleMacroOpenContext;

    public constructor(context: ISaleMacroOpenContext) {
        this.context = context;
    }

    public replace(template: string): string {
        return template.replace(this.regexp, (match: string, macro: string, args: string) => {
            try {
                const argsArray = args.length > 0 ? args.substring(1).split(",") : undefined;
                const value = this.replaceMacro(macro, argsArray) ?? match;
                return value;
                // return func(value);
            } catch {
                //console.warn(`Не удалось раскрыть макрос: ${match}.`);
                return match;
            }
        });
    }

    private replaceMacro(macroName: string, args?: string[]): string | null {
        const macro = this.findMacro(macroName);
        if (!macro) {
            return null;
        }

        return macro.open(this.context, args) ?? "";
    }

    private findMacro(macroName: string): IMacro<ISaleMacroOpenContext> | null {
        macroName = macroName.toLowerCase();

        for (const macro of SaleMacroList) {
            for (const alias of macro.alias) {
                if (alias.toLowerCase() === macroName) {
                    return macro;
                }
            }
        }

        return null;
    }
}

// export class SaleDocumentMacroHtmlReplacer extends SaleDocumentMacroReplacer {
//     public constructor(context: SalePrintContext) {
//         super(context);
//     }

//     public replaceSimple(template: string, model?: ITemplateDocument): string {
//         return this.replaceRegexp(template, value => `<span style="white-space: pre-wrap;">${value}</span>`);
//     }
// }
