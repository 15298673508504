import { ISelectOption } from "@core/types/common/select-options";
import { DateTimeUtils } from "@/utils/datetime.utils";
import { Formatter } from "@/utils/formatter";
import { Period, StringPair } from "@/core/types";

export abstract class PeriodUtils {
    public static getTypes(): Period[] {
        return [Period.Today, Period.Yesterday, Period.Week, Period.Month, Period.Custom];
    }

    public static getTypeText(type: Period): string {
        switch (type) {
            case Period.Today:
                return "Сегодня";
            case Period.Yesterday:
                return "Вчера";
            case Period.Week:
                return "Неделя";
            case Period.Month:
                return "Месяц";
            case Period.Custom:
                return "Выбрать даты";
        }
    }

    public static getOptions(): ISelectOption<Period>[] {
        return PeriodUtils.getTypes().map(t => ({
            id: t,
            name: PeriodUtils.getTypeText(t),
        }));
    }

    /** Вернуть даты периода от и до. */
    public static getDates(period: Period): StringPair | null {
        const format = "YYYY-MM-DD";

        let from: string = "";
        let to: string = "";

        switch (period) {
            case Period.Today:
                from = Formatter.now({ format });
                return [from, from];
            case Period.Yesterday:
                from = Formatter.datetime(DateTimeUtils.yesterday(), { format });
                return [from, from];
            case Period.Week:
                from = Formatter.datetime(DateTimeUtils.weekAgo(+1), { format });
                to = Formatter.now({ format });
                return [from, to];
            case Period.Month:
                from = Formatter.datetime(DateTimeUtils.monthAgo(+1), { format });
                to = Formatter.now({ format });
                return [from, to];
        }

        return null;
    }
}
