import { ReportKind } from "../report-filter-controller";
import { IReport, IReportOptions, IReportResult } from "./report";
import { FilterPair } from "@/utils/filter";
import { Formatter } from "@/utils/formatter";
import { currencyHeader } from "@/filters/money";

export class FinancesItemsTableReport implements IReport {
    public async generate(options: IReportOptions): Promise<IReportResult> {
        const dates = options.filter.dates as FilterPair;

        const from = dates[0];
        const to = dates[1];
        const accountId = options.filter.account as string;

        const usecase = options.context.$alt.system.usecase.createReportUseCase();
        const report = await usecase.finances(options.companyId, {
            timezone: new Date().getTimezoneOffset().toString(),
            from,
            to,
            account: accountId,
        });

        const accountName = report.account.info.name;

        const classHeaderNum = "va-td va-td-num text-center";
        const classHeaderName = "va-td text-center text-xl";
        const classHeaderMoney = "va-td va-td-money text-center text-xl";

        const classCellNum = "va-td va-td-num text-center";
        const classCellName = "va-td text-nowrap text-xl";
        const classCellMoney = "va-td va-td-money text-center text-xl";
        const classCellMoneyGreen = `${classCellMoney} text-success`;
        const classCellMoneyRed = `${classCellMoney} text-danger`;

        const classFooterName = "va-td text-right text-xl font-bold va-td-noborder";
        const classFooterMoney = "va-td va-td-money text-center text-xl font-bold";

        const headerIncome: any[] = [
            {
                cells: [
                    {
                        value: "№",
                        class: classHeaderNum,
                    },
                    {
                        value: "Статья прихода",
                        class: classHeaderName,
                    },
                    {
                        value: currencyHeader("Сумма", report.account.info.currency),
                        class: classHeaderMoney,
                    },
                ],
            },
        ];

        const headerExpense: any[] = [
            {
                cells: [
                    {
                        value: "№",
                        class: classHeaderNum,
                    },
                    {
                        value: "Статья расхода",
                        class: classHeaderName,
                    },
                    {
                        value: currencyHeader("Сумма", report.account.info.currency),
                        class: classHeaderMoney,
                    },
                ],
            },
        ];

        const itemsObj: any = {};
        for (const transaction of report.transactions) {
            if (itemsObj[transaction.item.id]) {
                itemsObj[transaction.item.id].sum += transaction.amount;
            } else {
                itemsObj[transaction.item.id] = {
                    name: transaction.item.name,
                    type: transaction.item.type,
                    sum: transaction.amount,
                };
            }
        }

        let sumIncomes = 0.0;
        let sumExpenses = 0.0;
        let numIncome = 1;
        let numExpense = 1;
        let bodyIncome: any[] = [];
        let bodyExpense: any[] = [];
        for (const itemId in itemsObj) {
            const item = itemsObj[itemId];
            const amount = item.sum;
            const amountStr = Formatter.money(amount);

            if (item.type > 0) {
                bodyIncome.push({
                    cells: [
                        {
                            value: numIncome,
                            class: classCellNum,
                        },
                        {
                            value: item.name,
                            class: classCellName,
                        },
                        {
                            value: amount > 0 ? `+${amountStr}` : amountStr,
                            class: amount > 0 ? classCellMoneyGreen : amount < 0 ? classCellMoneyRed : classCellMoney,
                        },
                    ],
                });

                sumIncomes += amount;
                numIncome += 1;
            }

            if (item.type < 0) {
                bodyExpense.push({
                    cells: [
                        {
                            value: numExpense,
                            class: classCellNum,
                        },
                        {
                            value: item.name,
                            class: classCellName,
                        },
                        {
                            value: amount > 0 ? `+${amountStr}` : amountStr,
                            class: amount > 0 ? classCellMoneyGreen : amount < 0 ? classCellMoneyRed : classCellMoney,
                        },
                    ],
                });

                sumExpenses += amount;
                numExpense += 1;
            }
        }

        // TODO: sort by name and re-assign num

        bodyIncome = bodyIncome.sort((row1, row2) => {
            if (row1.cells[1].value > row2.cells[1].value) {
                return 1;
            }
            if (row1.cells[1].value < row2.cells[1].value) {
                return -1;
            }
            return 0;
        });

        bodyIncome.forEach((row, index) => (row.cells[0].value = index + 1));

        bodyExpense = bodyExpense.sort((row1, row2) => {
            if (row1.cells[1].value > row2.cells[1].value) {
                return 1;
            }
            if (row1.cells[1].value < row2.cells[1].value) {
                return -1;
            }
            return 0;
        });

        bodyExpense.forEach((row, index) => (row.cells[0].value = index + 1));

        const footerIncome: any[] = [
            {
                cells: [
                    {
                        value: "Итого приход:",
                        class: classFooterName,
                        colspan: 2,
                    },
                    {
                        value: Formatter.money(sumIncomes),
                        class: classFooterMoney,
                    },
                ],
            },
        ];

        const footerExpense: any[] = [
            {
                cells: [
                    {
                        value: "Итого расход:",
                        class: classFooterName,
                        colspan: 2,
                    },
                    {
                        value: Formatter.money(sumExpenses),
                        class: classFooterMoney,
                    },
                ],
            },
        ];

        const format = "LL";
        const subtitle =
            from === to
                ? Formatter.datetime(from, { format })
                : `${Formatter.datetime(from, { format })} - ${Formatter.datetime(to, { format })}`;

        return {
            title: `Движение средств по счёту "${accountName}" по статьям`,
            subtitle: subtitle,
            items: [
                {
                    kind: ReportKind.Table,
                    table: {
                        header: headerIncome,
                        body: bodyIncome,
                        footer: footerIncome,
                    },
                },
                {
                    kind: ReportKind.Table,
                    table: {
                        header: headerExpense,
                        body: bodyExpense,
                        footer: footerExpense,
                    },
                },
            ],
        };
    }

    private dtFormat(date: number | string | Date): string {
        return Formatter.datetime(date, { format: "MMM 'YY" });
    }
}
