import { IOrderTypeField, IOrderTypeFieldGroup, OrderTypeFieldGroupType } from "@lib";
import { FieldControlFactory } from "@core/types/field-controls/field-control-factory";
import { OrderMacroList } from "@core/usecases/template/macro-replacers/order-document.macro-list";
import { IMacro, IOrderMacroOpenContext } from "@core/usecases/template/macro";
import { IMacroReplacer } from "./macro-replacer";

export type GroupedCustomField = {
    field: IOrderTypeField;
    group: IOrderTypeFieldGroup;
};

export class OrderMacroReplacer implements IMacroReplacer {
    // %(макрос:аргумент1,аргумент2)
    private readonly regexp = /%\((?<macro>[\p{L}\p{N}.]*)(:?(?<args>[\p{L}\p{N}.,]*)?)\)/giu;
    // \p{L} - юникодные буквы
    // \p{N} - юникодные цифры
    // регулярки: https://developer.mozilla.org/ru/docs/Web/JavaScript/Guide/Regular_Expressions
    // юникодные символы: https://learn.javascript.ru/regexp-unicode
    // проверка регулярок: https://regex101.com/

    private readonly customFields: GroupedCustomField[];
    private readonly context: IOrderMacroOpenContext;

    public constructor(context: IOrderMacroOpenContext) {
        this.context = context;
        this.customFields = this.getCustomFields();
    }

    public replace(template: string): string {
        return template.replace(this.regexp, (match: string, macro: string, args: string) => {
            try {
                const argsArray = args.length > 0 ? args.substring(1).split(",") : undefined;
                const value = this.replaceMacro(macro, argsArray) ?? this.replaceCustomMacro(macro) ?? match;
                return value;
                // return func(value);
            } catch {
                // console.warn(`Не удалось раскрыть макрос: ${match}.`);
                return match;
            }
        });
    }

    private getCustomFields(): GroupedCustomField[] {
        const fields: GroupedCustomField[] = [];

        if (!this.context.orderType) {
            return fields;
        }

        for (const group of this.context.orderType.groups) {
            for (const field of group.fields) {
                if (field.custom) {
                    fields.push({ field, group });
                }
            }
        }

        return fields;
    }

    private replaceMacro(macroName: string, args?: string[]): string | null {
        const macro = this.findMacro(macroName);

        if (!macro) {
            return null;
        }

        return macro.open(this.context, args) ?? "";
    }

    private findMacro(macroName: string): IMacro<IOrderMacroOpenContext> | null {
        macroName = macroName.toLowerCase();

        for (const macro of OrderMacroList) {
            for (const alias of macro.alias) {
                if (alias.toLowerCase() === macroName) {
                    return macro;
                }
            }
        }

        return null;
    }

    private replaceCustomMacro(macroName: string): string | null {
        const customField = this.findCustomFieldByMacro(macroName);
        if (!customField) {
            return null;
        }

        const order = this.context.order;
        const locale = order.officeRef?.info?.locale;
        const field = customField.field;

        // TODO: повтор, как в orders-defaults
        const key = field.customId ?? field.details1 ?? field.id;
        let value: any;

        if (customField.group.type === OrderTypeFieldGroupType.Client && order.client) {
            const client = order.clientRef;
            value = client?.custom ? client.custom[key] : undefined;
        } else if (customField.group.type === OrderTypeFieldGroupType.Product && order.products?.length > 0) {
            const product = order.products[0];
            value = product.custom ? product.custom[key] : undefined;
        } else {
            value = order.custom ? order.custom[key] : undefined;
        }

        return FieldControlFactory.formatValue(value, field, { locale });
    }

    private findCustomFieldByMacro(macroName: string): GroupedCustomField | null {
        macroName = macroName.toLowerCase();

        for (const customField of this.customFields) {
            if (!customField.field.macros) {
                continue;
            }

            for (const alias of customField.field.macros) {
                if (alias.trim().toLowerCase() === macroName) {
                    return customField;
                }
            }
        }

        return null;
    }
}

// export class OrderDocumentMacroHtmlReplacer extends OrderDocumentMacroReplacer {
//     public constructor(context: OrderPrintContext) {
//         super(context);
//     }

//     public replaceSimple(template: string, model?: ITemplateDocument): string {
//         return this.replaceRegexp(template, value => `<span style="white-space: pre-wrap;">${value}</span>`);
//     }
// }
